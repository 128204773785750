import React, { useState } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Button from "./Button";
import Twitter from '../Icons/Twitter'
import Discord from '../Icons/Discord'

const Section = styled.section`
width: 100vw;
background-color: ${props => props.theme.body};
color: ${(props) => props.theme.text};

`

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem 7rem;

&>*{
  padding-right: 0.7rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`

const Navbar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;

width: 85%;
height: ${props => props.theme.navHeight};
margin: 0 auto;

.mobile{
    display: none;
}

@media (max-width: 64em) {
    .desktop{
    display: none;
}
.mobile{
    display: inline-block;
}
}

`
const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;



@media (max-width: 64em) {
    /* 1024 px */
    position: fixed;
    top: ${props => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${props => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 50;
    background-color:  ${props => `rgba(${props.theme.textRgba}, 0.85)`};
    backdrop-filter: blur(2px);

    transform: ${props => props.click ? 'translateY(0)' : 'translateY(1000%)'};
    transition: all 0.3s ease;

    flex-direction: column;
    justify-content: center;

    touch-action: none;


}
`

const MenuItem = styled.li`
margin: 0 1rem 1rem 1rem;
color: ${props => props.theme.text};
cursor: pointer;


&::after{
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: ${props => props.theme.text};
    transition: width 0.3s ease;
}


&:hover::after{
    width: 100%;
}

@media (max-width: 64em) {
    margin: 1rem 0;
    
}


`



const HamburgerMenu = styled.span`
width: ${props => props.click ? '2rem' : '1.5rem'};
height: 2px;
background: ${props => props.theme.text};

position: absolute;
top: 2rem;
left: 50%;
transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)'  : 'translateX(-50%) rotate(0)'  };


justify-content: center;
align-items: center;

cursor: pointer;
transition: all 0.3s ease;
display:none;

@media (max-width: 64em) {
    /* 1024 px */
    display: flex;
    justify-content: center;
align-items: center;

}


&::after, &::before{
    content: ' ';
    width: ${props => props.click ? '1rem' : '1.5rem'};
    height: 2px;
    right: ${props => props.click ? '-2px' : '0'};
    background: ${props => props.theme.text};
    position: absolute;
    
}

&::after{
    top: ${props => props.click ? '0.3rem' : '0.5rem'};
    transform: ${props => props.click ? 'rotate(-40deg)'  : 'rotate(0)'  };
}

&::before{
    bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
    transform: ${props => props.click ? 'rotate(40deg)'  : 'rotate(0)'  };
}
`


const Navigation = () => {

    const [click, setclick] = useState(false);

    const scrollTo = (id) => {

        let element = document.getElementById(id);

        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        })

        setclick(!click); //after clicking event
    }


    return(
        <Section  id= "home">
            
            <Navbar>
            <HamburgerMenu click={click} onClick={() => setclick(!click)}>
                    &nbsp;
                </HamburgerMenu>
                <Logo />
                
                <Menu click={click}>
                    <MenuItem onClick={() => scrollTo('home')}>Home</MenuItem>
                    <MenuItem onClick={() => scrollTo('about')}>About</MenuItem>
                    <MenuItem onClick={() => scrollTo('roadmap')}>Roadmap</MenuItem>
                    <MenuItem onClick={() => scrollTo('showcase')}>Showcase</MenuItem>
                    <MenuItem onClick={() => scrollTo('team')}>Team</MenuItem>
                    <MenuItem onClick={() => scrollTo('faq')}>Faq</MenuItem>
                    <MenuItem><a href="https://mint.forgottenrealm.xyz/" target="_blank" rel="noopener noreferrer">Mint</a></MenuItem>
                
                    <IconList>
                    <a href="https://discord.gg/Ud8M89jf3d" target='_blank' rel="noopener noreferrer">
                    <Discord />
                    </a>
                    <a href="https://Twitter.com/ForgottenNFT_" target='_blank' rel="noopener noreferrer">
                    <Twitter />
                    </a>
                    </IconList>

                </Menu>
                
               
            </Navbar>
        </Section>
    )
}

export default Navigation